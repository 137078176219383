import {getOptionsFromJsonString} from "./utilities/options";

function build360Viewer(wrapper) {
    const viewerElement = wrapper;
    viewerElement.classList.add("is-loading");

    let options = getOptionsFromJsonString(wrapper.getAttribute('data-options'), {
        "location": "/userdata/dcshop/360_degree_images/7fbc04d608d48fa21eea83b607ab2bc2/",
        "filename": "ebene_",
        "format": "png",
        "count": 92,
        "startAutoRotation": true
    });

    const container = document.createElement('div');
    container.classList.add("viewer360Images");

    const loader = document.createElement('div');
    loader.classList.add("viewer360__loader");
    viewerElement.appendChild(loader);

    loader.innerText = "0%";

    const images = [];
    let loadedImages = 0;

    for (let i = 1; i <= options.count; i++) {
        const image = document.createElement('img');
        image.onload = function() {
            loadedImages++;
            const percentLoaded = Math.round((loadedImages / options.count) * 100);

            loader.innerText = percentLoaded+"%";

            if (loadedImages === options.count) {
                viewerElement.classList.add("loaded");
                viewerElement.classList.remove('is-loading');

                if(options.startAutoRotation){
                    startAutoRotation();
                }
            }
        };
        image.src = `${options.location}${options.filename}${i}.${options.format}`;
        container.appendChild(image);
        images.push(image);
    }

    viewerElement.appendChild(container);

    const settings = {
        currentImage: 0,
        images: images,
        sensitivity: 3,
        isDragging: false,
        lastPosition: null
    };

    function updateViewer(){
        let hasActive = false;
        for(let key in settings.images){
            let image = settings.images[key];
            image.classList.remove("is-active");
            if(parseInt(key) === settings.currentImage){
                image.classList.add("is-active");
                hasActive = true;
            }
        }
        if(!hasActive){
            settings.images[0].classList.add("is-active");
        }
    }

    function onMouseDown(event) {
        settings.isDragging = true;
        if (event.touches) {
            event.clientX = event.touches[0].clientX;
            event.clientY = event.touches[0].clientY;
        }
        settings.lastPosition = {x: event.clientX, y: event.clientY};
    }

    function onMouseMove(event) {
        if (settings.isDragging) {
            if (event.touches) {
                event.clientX = event.touches[0].clientX;
                event.clientY = event.touches[0].clientY;
            }
            const delta = event.clientX - settings.lastPosition.x;
            settings.currentImage += delta / settings.sensitivity;
            if (settings.currentImage < 0) {
                settings.currentImage = settings.images.length - 1;
            } else if (settings.currentImage >= settings.images.length) {
                settings.currentImage = 0;
            }
            settings.currentImage = Math.round(settings.currentImage);
            settings.lastPosition = {x: event.clientX, y: event.clientY};
            updateViewer();
        }
    }

    function onMouseUp() {
        settings.isDragging = false;
    }

    function startAutoRotation() {
        let currentImage = 0;

        let interval = setInterval(function (){
            settings.currentImage = currentImage;
            updateViewer();
            currentImage++;
            if(currentImage > options.count){
                clearInterval(interval);
            }
        },50);
    }

    container.addEventListener('mousedown', onMouseDown);
    container.addEventListener('mousemove', onMouseMove);
    container.addEventListener('touchstart', onMouseDown);
    container.addEventListener('touchmove', onMouseMove);
    document.addEventListener('touchend', onMouseUp);
    document.addEventListener("mouseup", onMouseUp);

    updateViewer();
}

export function init360Images(query = document.querySelectorAll('.js-load360')) {
    for (let image360 of query) {
        if(!image360.classList.contains("loaded") && !image360.classList.contains("is-loading")){
            build360Viewer(image360);
        }
    }
}