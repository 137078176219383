
function addIconFileInput(inputBody){
    let i = document.createElement("i");

    i.classList.add("icon");

    inputBody.appendChild(i);

    return i;
}

function checkFilledFileInput(inputBody){
    let input = inputBody.querySelector("input");
    let span = inputBody.querySelector("span");
    let i = inputBody.querySelector('i');

    let labelChoose = "Select file";
    let labelChosen = "files selected";

    if (typeof GLOBALS !== 'undefined') {
        if(GLOBALS.tc && GLOBALS.tc.chooseFiles){
            labelChoose = GLOBALS.tc.chooseFiles;
        }
        if(GLOBALS.tc && GLOBALS.tc.chosenFiles){
            labelChosen = GLOBALS.tc.chosenFiles;
        }
    }

    let value = "";
    if(input.files.length > 0) {
        value = input.files[0].name;
        if(input.files.length > 1) {
            value = input.files.length + " " + labelChosen;
        }
        i.classList.add("icon-trash");
        i.classList.remove("icon-upload");
        inputBody.classList.add("is-active");
    }else{
        value = labelChoose;
        i.classList.add("icon-upload");
        i.classList.remove("icon-trash");
        inputBody.classList.remove("is-active");
    }
    span.innerHTML = value;
}

export function initInputBody(query = document.querySelectorAll(".fileBody")) {
    for(let inputBody of query) {
        let input = inputBody.querySelector("input");
        let span = inputBody.querySelector("span");

        if(input && span) {

            let infieldInputGroup = input.closest(".infieldInputGroup");
            if(infieldInputGroup){
                infieldInputGroup.classList.add("is-focus");
            }
            let i = addIconFileInput(inputBody);
            checkFilledFileInput(inputBody);
            input.addEventListener("change", function () {
                checkFilledFileInput(inputBody);
            });

            i.addEventListener("click",function (){
                if(inputBody.classList.contains("is-active")){
                    input.value = "";
                    checkFilledFileInput(inputBody);
                }else{
                    input.click();
                }
            });
        }
    }
}