
export function isTouchDevice() {
    if (('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0)) {
        return true;
    }

    if ('mousemove' in window) {
        return false;
    }
    return false;
}