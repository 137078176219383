function checkValue(fields) {
    let count = 0;
    let value = "";
    let valid = true;
    for (let field of fields) {
        field.classList.remove("error");
        field.setCustomValidity('');
        if (count === 0) {
            value = field.value;
        } else {
            if (value !== field.value) {
                valid = false;
                field.classList.add("error");
                if (GLOBALS.tc.hint_same_passwords) {
                    field.setCustomValidity(GLOBALS.tc.hint_same_passwords);
                }
            }
        }
        count++;
    }

    return valid;
}

function togglePasswordFields(wrapper) {
    wrapper.classList.toggle("is-active");
    for (let field of wrapper.querySelectorAll('input')) {
        if (wrapper.classList.contains("is-active")) {
            field.setAttribute("type", "text");
        } else {
            field.setAttribute("type", "password");
        }
    }
    for (let icon of wrapper.querySelectorAll('.passwordToggle .icon')) {
        icon.classList.toggle("icon-password-show");
        icon.classList.toggle("icon-password-hide");
    }
}

function generatePasswordToggle(field, wrapper) {
    let togglePassword = document.createElement("div");
    togglePassword.classList.add("passwordToggle");
    togglePassword.classList.add("inputGroup__addon");
    togglePassword.classList.add("inputGroup__addon--clickable");
    togglePassword.innerHTML = "<i class='icon icon-password-show'></i>";
    field.closest('.inputGroup').appendChild(togglePassword);
    field.closest('.inputGroup').classList.add('inputGroup--infieldAddon');
    togglePassword.addEventListener("click", function () {
        togglePasswordFields(wrapper);
    });
}

export function initPasswordFields() {
    for (let wrapper of document.querySelectorAll('.js-passwordFields')) {
        let fields = wrapper.querySelectorAll('input[type="password"]');
        if (fields) {
            for (let field of fields) {
                //generatePasswordToggle(field, wrapper);
                field.addEventListener("change", function () {
                    checkValue(fields);
                });
            }
        }
    }
}