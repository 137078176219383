import {slideDown, slideUp} from "./utilities/slide";
import {changeRequiredAttributes} from "./order";

export function initToggleSlide(query = document.querySelectorAll(".js-toggleLinkSlide")) {
    for (let toggle of query) {
        toggle.addEventListener("click", function () {
            let dataset = toggle.dataset;
            let toggleTar = "";
            let wrapper = toggle.closest('.js-toggleWrapper');

            if (dataset.toggleTarget) {
                toggleTar = "." + dataset.toggleTarget;
            } else {
                console.error("toggle targets not defined");
                return;
            }

            if (wrapper === null) {
                wrapper = document;
            }

            for (let target of (wrapper.querySelectorAll(toggleTar))) {
                if (toggle.classList.contains("is-active")) {
                    slideUp(target,200,"linear",function (){
                        changeRequiredAttributes(target = document.querySelectorAll('.isRequiredVisible'),false);
                    });
                    toggle.classList.remove("is-active");
                } else {
                    slideDown(target, 200, "linear", function () {
                        let input = target.querySelector('input:not([type=hidden])');
                        if (input) {
                            input.focus();
                        }
                        changeRequiredAttributes(target = document.querySelectorAll('.isRequiredVisible'));
                    });
                    toggle.classList.add("is-active");
                }
            }
        });
    }
}