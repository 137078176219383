import anime from 'animejs/lib/anime.es.js';

export function fadeIn(el, duration = 200, easing = "linear", callback = function () {
},displayBlock = false) {
    if (el !== null) {
        if(displayBlock){
            el.style.display = "block";
        }else{
            el.style.display = "flex";
        }
        el.style.opacity = 0;
        anime({
            targets: el,
            opacity: 1,
            easing: easing,
            duration: duration,
            complete: function () {
                callback();
            }
        });
    }
}

export function fadeOut(el, duration = 200, easing = "linear", callback = function () {
}) {
    if (el !== null) {
        anime({
            targets: el,
            opacity: 0,
            easing: easing,
            duration: duration,
            complete: function () {
                el.style.display = "none";
                callback();
            }
        });
    }
}

export function fadeToggle(el, duration = 200, easing = "linear", callback = function () {
}) {
    if (el.style.display === "none" || el.style.display === "") {
        fadeIn(el, duration, easing, callback);
    } else {
        fadeOut(el, duration, easing, callback);
    }
}