export function getChildrenClassForNestedItems(parent, childrenTagName = "ul", childrenClass = "hasChildren") {
    let hasChildren = false;
    let childrenLink = false;
    for (let children of parent.children) {
        if (children.tagName === childrenTagName.toUpperCase()) {
            hasChildren = true;
        }
        if (children.tagName === 'A' || children.tagName === 'SPAN') {
            childrenLink = children;
        }
    }

    if (hasChildren) {
        let triggerOpener = document.createElement("span");
        triggerOpener.classList.add("navChildrenToggle");
        childrenLink.appendChild(triggerOpener);

        triggerOpener.addEventListener("click", function (e) {
            let closestLi = triggerOpener.closest('li');
            if (closestLi.classList.contains("open")) {
                triggerOpener.closest('li').classList.remove("open");
                triggerOpener.classList.remove("active");
            } else {
                triggerOpener.closest('li').classList.add("open");
                triggerOpener.classList.add("active");
            }
            e.preventDefault();
        })

        parent.classList.add(childrenClass);
    }
}