const hoverintentElements  = new Map();
const currentHoverIntentElements = new Map();
let timeOutHoverintent;
let firstHoverintent = true;

function updateHoverintentElements(){
    for(let curr of currentHoverIntentElements.keys()){
        curr.classList.add("hoverintent");
    }
    for(let hover of document.querySelectorAll('.hoverintent')){
        let values = currentHoverIntentElements.get(hover);
        if(values.delete){
            hover.classList.remove("hoverintent");
            let callbackLeave = values.callbackLeave;
            if(callbackLeave){
                callbackLeave();
            }
            currentHoverIntentElements.delete(hover);
        }else{
            hover.classList.add("hoverintent");
            let callbackEnter = values.callbackEnter;
            if(callbackEnter){
                callbackEnter();
            }
        }
    }
}

function initHoverintentTouchEvents(){
    document.addEventListener("touchstart",function (e){
        for(let hover of hoverintentElements.keys()){
            if(hover.contains(e.target)){
                currentHoverIntentElements.set(hover,hoverintentElements.get(hover));
                if(!hover.classList.contains("hoverintent")){
                    e.preventDefault();
                    e.stopImmediatePropagation();
                }
            }else{
                currentHoverIntentElements.set(hover,{
                    ...currentHoverIntentElements.get(hover),
                    ...{delete: true}
                });
            }
        }
        updateHoverintentElements();
    },{passive:false});
    firstHoverintent = false;
}

function hoverintent(element, duration = 200, callbackEnter = () => {}, callbackLeave = () => {}) {
    hoverintentElements.set(element, {
        callbackEnter: callbackEnter,
        callbackLeave: callbackLeave
    });

    let timeoutEnter;
    let timeoutLeave;

    const handleMouseEnter = () => {
        clearTimeout(timeoutLeave);
        timeoutEnter = setTimeout(() => {
            currentHoverIntentElements.set(element, {
                callbackEnter: callbackEnter,
                callbackLeave: callbackLeave
            });
            updateHoverintentElements();
        }, duration);
    };

    const handleMouseLeave = () => {
        clearTimeout(timeoutEnter);
        timeoutLeave = setTimeout(() => {
            currentHoverIntentElements.set(element, {
                ...currentHoverIntentElements.get(element),
                ...{ delete: true }
            });
            updateHoverintentElements();
        }, duration);
    };

    element.addEventListener("mouseenter", handleMouseEnter);
    element.addEventListener("mouseleave", handleMouseLeave);

    if (firstHoverintent) {
        initHoverintentTouchEvents();
    }
}

export default hoverintent;
