import {slideDown, slideUp} from "./utilities/slide";

function openSlidecontentItem(slidecontentItem) {
    slidecontentItem.classList.add("is-active");
    let content = slidecontentItem.querySelector(".slidecontentItem__content");
    if (content) {
        slideDown(content)
    }
}

function closeSlidecontentItem(slidecontentItem) {
    slidecontentItem.classList.remove("is-active");
    let content = slidecontentItem.querySelector(".slidecontentItem__content");
    if (content) {
        slideUp(content);
    }
}

export function initSlidecontents(query = document.querySelectorAll('.slidecontentItem__headline')) {

    let openedFirst = false;

    for (let el of query) {

        let slidecontentItem = el.closest('.slidecontentItem');

        if (slidecontentItem.closest('.slidecontent').closest('.first-opened') && openedFirst === false ) {
            openSlidecontentItem(slidecontentItem);
            openedFirst = true;
        }

        el.addEventListener("click", function () {
            if (slidecontentItem.classList.contains("is-active")) {
                closeSlidecontentItem(slidecontentItem);
            } else {
                openSlidecontentItem(slidecontentItem);
            }
        });
    }
}