import anime from 'animejs/lib/anime.es.js';

export function slideDown(el, duration = 200, easing = "linear", complete = function () {
}, display = "block") {
    if (el !== null) {
        el.style.display = display;
        const heightSubnavi = anime.get(el, "height");
        el.style.visibility = "hidden";
        el.style.height = 0;
        el.style.overflow = "hidden";
        anime({
            targets: el,
            height: heightSubnavi,
            easing: easing,
            duration: duration,
            begin: function () {
                el.style.visibility = '';
            },
            complete: function () {
                el.style.overflow = '';
                el.style.height = '';
                complete();
            }
        });
    }
}

export function slideUp(el, duration = 200, easing = "linear", complete = function () {
}) {
    if (el !== null) {
        anime({
            targets: el,
            height: 0,
            easing: easing,
            duration: duration,
            begin: function () {
                el.style.visibility = '';
                el.style.overflow = "hidden";
            },
            complete: function () {
                el.style.height = '';
                el.style.overflow = "";
                el.style.display = "none";
                complete();
            }
        });
    }
}

export function slideToggle(el, duration = 200, easing = "linear", complete = function () {
}, display = "block") {
    if (el.style.display === "none" || el.style.display === "") {
        slideDown(el, duration, easing, complete, display);
    } else {
        slideUp(el, duration, easing, complete);
    }
}