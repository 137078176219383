import {loadScript} from "./utilities/loadScript";

export function lottieLoader() {
    let lottieWrapper = document.querySelectorAll('.js-lottie');
    if(lottieWrapper.length > 0){
        loadScript('/plugins/lottie/lottie.min.js').then(() => {
            for (let lottieLoader of lottieWrapper) {
                let item = lottie.loadAnimation({
                    wrapper: lottieLoader,
                    animType: 'svg',
                    loop: true,
                    autoplay: true,
                    path: lottieLoader.dataset.path
                });
            }
        }).catch(() => {
            console.log('Error loading lottie');
        });
    }
}