
function setActiveContentTab(contentTabs, activeId = 0){
    let contentTabsHeader = contentTabs.querySelector('.contentTabs__header');
    let contentTabsContent = contentTabs.querySelector('.contentTabs__content');

    if(contentTabsHeader && contentTabsContent){
        let contents = contentTabsContent.children;
        let contentHeaderChildren = contentTabsHeader.children;

        if(contents){
            let count = 0;
            for(let contentHeaderTab of contentHeaderChildren){
                contentHeaderTab.classList.remove("is-active");
                if(count === activeId){
                    contentHeaderTab.classList.add("is-active");
                }
                count++;
            }
            count = 0;
            for(let content of contents){
                content.classList.remove("is-active");
                if(count === activeId){
                    content.classList.add("is-active");
                }
                count++;
            }
        }
    }
}

function buildContentTabs(contentTabs){
    let contentTabsHeader = contentTabs.querySelector('.contentTabs__header');
    let contentTabsContent = contentTabs.querySelector('.contentTabs__content');

    if(contentTabsHeader && contentTabsContent){
        let contents = contentTabsContent.children;

        if(contents){
            for(let content of contents){
                content.classList.add("contentTabs__contentItem");
            }
        }
    }

    contentTabs.classList.add("initialized");
}

function getCurrentIndex(tabs,currentTab){
    let index = 0;

    for(let tab of tabs){
        if(tab === currentTab){
            return index;
        }
        index++;
    }
}

function setEventListenerForTab(contentTabs){
    let contentTabsHeader = contentTabs.querySelector('.contentTabs__header');
    if(contentTabsHeader){
        let contentHeaderChildren = contentTabsHeader.children;

        if(contentHeaderChildren){
            for(let tab of contentHeaderChildren){
                tab.addEventListener("click",function (){
                    setActiveContentTab(contentTabs,getCurrentIndex(contentHeaderChildren,tab));
                });
            }
        }
    }
}

export function initContentTabs(wrapper = document){
    for(let contentTabs of wrapper.querySelectorAll('.contentTabs')){
        buildContentTabs(contentTabs);
        setActiveContentTab(contentTabs);
        setEventListenerForTab(contentTabs);
    }
}