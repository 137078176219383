
export function initScrollActivate(scrollActivateItems = document.querySelectorAll('.scrollActivate')){
    if (scrollActivateItems.length) {
        document.addEventListener("scroll", function (e) {
            for (let scrollActivate of scrollActivateItems) {
                let boundingClientRect = scrollActivate.getBoundingClientRect();
                if (boundingClientRect) {
                    let startPosition = boundingClientRect.y + boundingClientRect.height / 2;
                    let windowOuterHeight = window.outerHeight;
                    if (boundingClientRect.height > windowOuterHeight) {
                        startPosition = boundingClientRect.y;
                    }
                    if ((startPosition) < windowOuterHeight / 3 * 2) {
                        scrollActivate.classList.add('is-active');
                    }
                }
            }
        });
    }
}