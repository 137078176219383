import Swiper from 'swiper';
import { Autoplay, EffectCoverflow, Navigation } from 'swiper/modules';
import {getOptionsFromJsonString} from "./utilities/options";

export function initHistorySlider(query = document.querySelectorAll('.historySlider')) {
    for (let slider of query) {
        let options = getOptionsFromJsonString(slider.dataset.options, {
            autoplay_interval: 5000,
            eff_interval: 300,
        });
        Swiper.use([Autoplay, Navigation,EffectCoverflow]);
        new Swiper(slider, {
            loop: true,
            speed: options.eff_interval,
            centeredSlides: true,
            slidesPerView: "auto",
            effect: "coverflow",
            coverflowEffect: {
                rotate: 0,
                stretch: 0,
                depth: 100,
                modifier: 1,
                scale: 0.85,
                slideShadows: false,
            },
            autoplay: {
                delay: options.autoplay_interval,
            },
            navigation: {
                nextEl: slider.querySelector('.swiper-button-next'),
                prevEl: slider.querySelector('.swiper-button-prev'),
            }
        });
    }
}
