import flatpickr from "flatpickr";
import {German} from "flatpickr/dist/l10n/de";
import {getOptionsFromJsonString} from "./utilities/options";

function disableWeekend(date) {
    return (date.getDay() === 0 || date.getDay() === 6);
}

function disableSunday(date) {
    return (date.getDay() === 0);
}

function updateDatepickerFormGroup(picker){
    let inputGroup = picker.closest(".inputGroup");
    if(inputGroup){
        if(picker.value) {
            inputGroup.classList.remove("is-inactive");
        }else{
            inputGroup.classList.add("is-inactive");
        }
    }
}

function handleClearButton(flatpickr){
    let inputDate = flatpickr.input;
    let inputGroup = inputDate.closest(".inputGroup");
    if(inputGroup){
        let deleteButton = inputGroup.querySelector(".js-datepicker-clear");
        if(deleteButton){
            deleteButton.addEventListener("click",function (){
                flatpickr.clear();
                updateDatepickerFormGroup(inputDate);
            });
        }else{
            console.error("cannot find datepicker clear button");
        }
    }
}

export function initDatepicker(query = document.querySelectorAll('.js-datepicker'), onValueUpdate = function () {
}) {
    let locale = false;

    if(GLOBALS){
        if(GLOBALS.config.languageCode === "de"){
            locale = German;
        }
    }
    for(let picker of query){
        let options = getOptionsFromJsonString(picker.dataset.options, {
            hideWeekend: false,
            hideSunday: false
        });
        let min = "today";
        let minDate = picker.getAttribute("min");
        if (minDate !== "today") {
            min = minDate;
        }

        updateDatepickerFormGroup(picker);

        if(options.hideSunday){
            flatpickr(picker, {
                "locale": locale,
                altInput: true,
                altFormat: "d.m.Y",
                minDate: min,
                dateFormat: "Y-m-d",
                onReady: function(selectedDates, dateStr, instance) {
                    handleClearButton(instance);
                },
                onValueUpdate: function(){
                    updateDatepickerFormGroup(picker);
                    onValueUpdate();
                },
                disable: [disableSunday]
            });
            return;
        }

        if(options.hideWeekend){
            flatpickr(picker, {
                "locale": locale,
                altInput: true,
                altFormat: "d.m.Y",
                minDate: min,
                dateFormat: "Y-m-d",
                onReady: function(selectedDates, dateStr, instance) {
                    handleClearButton(instance);
                },
                onValueUpdate: function(){
                    updateDatepickerFormGroup(picker);
                    onValueUpdate();
                },
                disable: [disableWeekend]
            });
            return;
        }

        flatpickr(picker, {
            "locale": locale,
            altInput: true,
            altFormat: "d.m.Y",
            minDate: min,
            dateFormat: "Y-m-d",
            onReady: function(selectedDates, dateStr, instance) {
                handleClearButton(instance);
            },
            onValueUpdate:function(){
                updateDatepickerFormGroup(picker);
                onValueUpdate();
            }
        });
    }
}