function formCheck(input) {
    const inputId = input.id;
    input.setAttribute("aria-invalid", !input.checkValidity());

    for (let notification of input.parentNode.querySelectorAll('.inputNotification')) {
        notification.remove();
    }

    if (!input.checkValidity()) {
        let errorMsg = input.validationMessage;
        input.setAttribute('aria-describedby', inputId + '-error');
        input.classList.add("error");

        let slidecontentWrapper = input.closest('.slidecontent__container');

        if (slidecontentWrapper && !slidecontentWrapper.classList.contains("is-active")) {
            slidecontentWrapper.classList.add("is-active");
        }
        return false;
    } else {
        input.removeAttribute('aria-describedby');
        input.classList.remove("error");
        return true;
    }
}

export function validateForm(form) {
    let formIsValid = true;

    for (let input of form.querySelectorAll('input:not([type=hidden]),select,textarea')) {
        let inputIsValid = formCheck(input);

        if (!inputIsValid) {
            formIsValid = false;
        }

        input.addEventListener(
            "blur", event => {
                formCheck(input);
            },
            false
        );
    }

    return formIsValid;
}

export function trimInputValues(query = document.querySelectorAll('.js-trimInputValue')) {
    for (let input of query) {
        input.addEventListener("change", function () {
            input.value = input.value.trim();
        });
    }
}